import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { createHead } from '@vueuse/head';
import { createApp } from 'vue';

import router from './router';
import App from './App.vue';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

// DEFINE
const app = createApp(App);
const head = createHead();

// Register Icon globally
app.component('font-awesome-icon', FontAwesomeIcon);

// Add fas icon set to the library
library.add(fas);

// USE
app.use(router);
app.use(head);

// INIT
app.mount('#app');