<template>
    <section class="portofolio-section margin-section-global">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <h3 class="extra-bold-fs-32 text-app-primary shadow-text-primary">Hasil Karya Kami</h3>
                    <p class="montserrat-text regular-fs-16 text-app-dark">Karya kami dihasilkan dari para client yang telah mempercayai <br> kami untuk membantu bisnis mereka</p>
                </div>
                <div class="col-12 mb-5">
                    <div class="slider-indicator-portofolio d-flex justify-content-between align-items-center px-2">
                        <transition-group name="slider">
                        <div
                            v-for="menuItem in menuItems"
                            :key="menuItem.id"
                            :class="['slider-menu-portofolio', {'slider-active-menu': menuItem.active}, {'slider-move-enter': menuItem.active}, {'slider-move-leave-to': !menuItem.active}]"
                            @click="changeActiveMenu(menuItem.id)"
                            :data-menu="menuItem.id"
                        >
                            <h5 class="mb-0 regular-fs-18 fw-bold">{{ menuItem.name }}</h5>
                        </div>
                        </transition-group>
                    </div>
                </div>

                <div class="col-12">
                    <div class="slider-content-portofolio position-relative">
                        <transition name="fade" mode="out-in">
                            <img :key="currentImage" :src="currentImageContent.content" :alt="currentImageContent.title" id="Portofolio-review">
                        </transition>
                        <div class="slider-content-detail position-relative d-flex align-items-center">
                            <div class="slider-content-indicator-1 text-start position-absolute">
                                <h6 class="mb-0 bold-fs-26 text-app-primary">{{ currentImageTitle }}</h6>
                                <a class="text-decoration-none text-app-blue montserrat-text mon mb-0" href="">
                                    {{ getLinkText }}
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.25 8.25L21 12M21 12L17.25 15.75M21 12H3" stroke="#015D7C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </a>
                            </div>
                            <div class="d-flex slider-content-indicator-2 position-absolute align-items-center">
                                <button class="btn slider-content-button rounded-circle text-white m-1" data-content-button="left"  @click="moveCarousel('left')">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.71983 12.5302C7.57938 12.3895 7.50049 12.1989 7.50049 12.0002C7.50049 11.8014 7.57938 11.6108 7.71983 11.4702L15.2198 3.97015C15.2885 3.89647 15.3713 3.83736 15.4633 3.79637C15.5553 3.75538 15.6546 3.73334 15.7553 3.73156C15.856 3.72979 15.956 3.74831 16.0494 3.78603C16.1428 3.82375 16.2276 3.8799 16.2989 3.95112C16.3701 4.02233 16.4262 4.10717 16.464 4.20056C16.5017 4.29394 16.5202 4.39397 16.5184 4.49468C16.5166 4.59538 16.4946 4.69469 16.4536 4.78669C16.4126 4.87869 16.3535 4.96149 16.2798 5.03015L9.30983 12.0002L16.2798 18.9702C16.3535 19.0388 16.4126 19.1216 16.4536 19.2136C16.4946 19.3056 16.5166 19.4049 16.5184 19.5056C16.5202 19.6063 16.5017 19.7064 16.464 19.7998C16.4262 19.8931 16.3701 19.978 16.2989 20.0492C16.2276 20.1204 16.1428 20.1766 16.0494 20.2143C15.956 20.252 15.856 20.2705 15.7553 20.2687C15.6546 20.267 15.5553 20.2449 15.4633 20.2039C15.3713 20.1629 15.2885 20.1038 15.2198 20.0302L7.71983 12.5302Z" fill="white"/>
                                    </svg>
                                </button>
                                <button class="btn slider-content-button rounded-circle text-white m-1" data-content-button="right"  @click="moveCarousel('right')">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2798 11.4698C16.4203 11.6105 16.4992 11.8011 16.4992 11.9998C16.4992 12.1986 16.4203 12.3892 16.2798 12.5298L8.77985 20.0298C8.63767 20.1623 8.44963 20.2344 8.25532 20.231C8.06102 20.2276 7.87564 20.1489 7.73822 20.0115C7.60081 19.8741 7.5221 19.6887 7.51867 19.4944C7.51524 19.3001 7.58737 19.112 7.71985 18.9698L14.6898 11.9998L7.71985 5.02985C7.58737 4.88767 7.51524 4.69963 7.51867 4.50532C7.5221 4.31102 7.60081 4.12564 7.73822 3.98822C7.87564 3.85081 8.06102 3.7721 8.25532 3.76867C8.44963 3.76524 8.63767 3.83737 8.77985 3.96985L16.2798 11.4698Z" fill="white"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "Portfolio",
        data() {
            return {
                currentImage: 0,
                images: [
                { content: require('@/assets/website/image/big/portofolio-1.png'), title: "Bionmed 1" }, 
                { content: require('@/assets/website/image/big/portofolio-1.png'), title: "Bionmed 2" }
                ],
                currentImageTitle: "",
                currentImageContent: {},
                menuItems: [
                    { id: 1, name: 'Website', active: true },
                    { id: 2, name: 'Aplikasi', active: false },
                    { id: 3, name: 'Dashboard', active: false }
                ],
                audios: {
                    website: new Audio(require('@/assets/website/audio/website.mp3')),
                    aplikasi: new Audio(require('@/assets/website/audio/application.mp3')),
                    dashboard: new Audio(require('@/assets/website/audio/dashboard.mp3'))
                }
            }
        },
        created() {
            this.currentImageTitle = this.images[this.currentImage].title;
            this.currentImageContent = this.images[this.currentImage];
        },
        computed: {
            getLinkText() {
                const selectedItem = this.menuItems.find(item => item.active);
                if (selectedItem) {
                    return `Lihat hasil ${selectedItem.name.toLowerCase()}`;
                }
                return "";
            }
        },
        methods: {
            moveCarousel(direction) {
                if (direction === 'left') {
                this.currentImage = (this.currentImage === 0) ? this.images.length - 1 : this.currentImage - 1;
                } else if (direction === 'right') {
                this.currentImage = (this.currentImage === this.images.length - 1) ? 0 : this.currentImage + 1;
                }
                this.currentImageTitle = this.images[this.currentImage].title;
                this.currentImageContent = this.images[this.currentImage];
            },
            changeActiveMenu(menuId) {
            this.menuItems.forEach(menuItem => {
                menuItem.active = menuItem.id === menuId;
            });
            switch (menuId) {
                case 1:
                this.playAudio('website');
                break;
                case 2:
                this.playAudio('aplikasi');
                break;
                case 3:
                this.playAudio('dashboard');
                break;
            }
            },
            playAudio(type) {
                const audio = this.audios[type];
                audio.currentTime = 0; 
                audio.play();
            }
        }
    }
</script>