<template>
    <section class="tagline-section margin-section-global">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="card p-5" id="card-border-custome">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 mb-3 text-center">
                                    <img :src="missionIcon" class="img-icon-heading mb-4" alt="">
                                    <h3>Our Mission</h3>
                                    <p class="text-secondary">
                                        Menjadi solusi bagi para pelaku usaha UMKM atau usaha lainnya yang ingin bersama-sama membangun pengembangan usaha melalui platform digital khususnya di Indonesia
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 mb-3 text-center">
                                    <img :src="visionIcon" class="img-icon-heading mb-4" alt="">
                                    <h3>Our Vission</h3>
                                    <p class="text-secondary">
                                        kami ingin mewujudkan sebuah perekonomian melalui digitalisasi agar lebih memudahkan berteransaksi dari penjual dan pembeli maupun dengan lainnya
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 mb-3 text-center">
                                    <img :src="goalsIcon" class="img-icon-heading mb-4" alt="">
                                    <h3>Our Goals</h3>
                                    <p class="text-secondary">
                                        Membantu memperluas jangkauan UMKM dan usaha lainnya untuk menjangkau pelanggan/pembeli/klien yang bisa membantu dan mewujudkan perekonomian 
                                    </p>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "Taglane",
        data() {
        return {
                missionIcon: require('@/assets/website/image/small/mission-opening.png'),
                visionIcon: require('@/assets/website/image/small/vision-opening.png'),
                goalsIcon: require('@/assets/website/image/small/goals-opening.png'),
            };
        }
    }
</script>