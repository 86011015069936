<template>
  <Navbar />
    <router-view/>
  <Footer />
</template>

<script>
import Navbar from '@/components/website/global/Navbar.vue'
import Footer from '@/components/website/global/Footer.vue'

export default {
  components: {
    Navbar,
    Footer
  },
  watch: {
    $route(to) {
      document.title = 'Homedevs | ' + to.meta.title || 'Homedevs';
    }
  }
}
</script>

<style src="@/assets/website/css/style.css"></style>