<template>
    <section class="footer-section">
        <footer class="container mb-4 bg-footer-section">
            <div class="row">
                <div class="col-12 p-5">
                    <div class="parent-footer-group justify-content-between">
                        <div class="list-footer-about-group mb-3 mb-lg-0">
                            <div class="group-logo-footer mb-3">
                                <div>
                                    <img :src="LogoFooter" alt="logo-web-inline.png" width="56" height="56">
                                </div>
                                <div class="m-2 text-white">
                                    <h6 class="mb-0 fw-bold regular-fs-20 poppins-text">Home Developer</h6>
                                    <p class="mb-0 poppins-text regular-fs-12 fw-light">Inovasi untuk bisnis digital</p>
                                </div>
                            </div>
                            <p class="mb-0 poppins-text regular-fs-12 text-white fw-light">Kami adalah software house yang berdiri sejak tahun 2020 kami bergerak di bidang perangkat lunak</p>
                        </div>

                        <div class="list-footer-menu-group">
                            <h6 class="mb-3 poppins-text bold-fs-14 text-white">Layanan Kami</h6>
                            <ul class="list-unstyled">
                                <li>
                                    <a class="regular-fs-13 text-decoration-none text-white fw-light poppins-text" href="#Website">Website</a>
                                </li>
                                <li>
                                    <a class="regular-fs-13 text-decoration-none text-white fw-light poppins-text" href="#Mobile Apps">Mobile Apps</a>
                                </li>
                                <li>
                                    <a class="regular-fs-13 text-decoration-none text-white fw-light poppins-text" href="#System Digital">System Digital</a>
                                </li>
                                <li>
                                    <a class="regular-fs-13 text-decoration-none text-white fw-light poppins-text" href="#Consultan">Consultan</a>
                                </li>
                            </ul>
                        </div>

                        
                        <div class="list-footer-menu-group">
                            <h6 class="mb-3 poppins-text bold-fs-14 text-white">Product</h6>
                            <ul class="list-unstyled">
                                <li>
                                    <a class="regular-fs-13 text-decoration-none text-white fw-light poppins-text" href="#bikinusaha.com">bikinusaha.com</a>
                                </li>
                            </ul>
                        </div>

                        <div class="list-footer-menu-group">
                            <h6 class="mb-3 poppins-text bold-fs-14 text-white">Portofolio</h6>
                            <ul class="list-unstyled">
                                <li>
                                    <a class="regular-fs-13 text-decoration-none text-white fw-light poppins-text" href="#Website">Website</a>
                                </li>
                                <li>
                                    <a class="regular-fs-13 text-decoration-none text-white fw-light poppins-text" href="#Mobile Apps">Mobile Apps</a>
                                </li>
                                <li>
                                    <a class="regular-fs-13 text-decoration-none text-white fw-light poppins-text" href="#System Digital">System Digital</a>
                                </li>
                            </ul>
                        </div>

                        <div class="list-footer-menu-group">
                            <h6 class="mb-3 poppins-text bold-fs-14 text-white">Cerita Kami</h6>
                            <ul class="list-unstyled">
                                <li>
                                    <a class="regular-fs-13 text-decoration-none text-white fw-light poppins-text" href="#Gallery">Gallery</a>
                                </li>
                                <li>
                                    <a class="regular-fs-13 text-decoration-none text-white fw-light poppins-text" href="#Social Media">Social Media</a>
                                </li>
                                <li>
                                    <a class="regular-fs-13 text-decoration-none text-white fw-light poppins-text" href="#Blog">Blog</a>
                                </li>
                            </ul>
                        </div>

                        <div class="list-footer-menu-group">
                            <h6 class="mb-3 poppins-text bold-fs-14 text-white">Hubungi</h6>
                            <ul class="list-unstyled">
                                <li>
                                    <a class="regular-fs-13 text-decoration-none text-white fw-light poppins-text" href="#+62 89533-1001-831">+62 89533-1001-831</a>
                                </li>
                                <li>
                                    <a class="regular-fs-13 text-decoration-none text-white fw-light poppins-text" href="#homedevs.id">homedevs.id</a>
                                </li>
                                <li>
                                    <a class="regular-fs-13 text-decoration-none text-white fw-light poppins-text" href="#devshome3@gmail.com">devshome3@gmail.com</a>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
                <div class="col-12 text-center p-3 p-lg-2">
                    <p class="mb-0 text-white poppins-text fw-light regular-fs-12">©{{currentYear}} PT Home Developer. Seluruh Hak Cipta.</p>
                </div>
            </div>
        </footer>
    </section>
</template>

<script>
    export default {
        name: "Footer",
        data() {
            return {
                LogoFooter: require('@/assets/website/logo/logo-web-inline.png'),
                currentYear: new Date().getFullYear()
            }
        },
    }
</script>

<style scoped>

</style>