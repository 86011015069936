<template lang="">

</template>

<script>
export default {
    name: 'AboutView',
}
</script>

<style lang="">
    
</style>