import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/website/HomeView.vue'
import AboutView from '../views/website/AboutView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { 
      title: 'Home', 
      description: 'Homedevs',
    }
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta: { 
      title: 'About',
      description: 'Homedevs',
     }
  },
  // {
  //   path: '/article/:id',
  //   component: Article,
  //   props: true,
  //   meta: (route) => ({
  //     title: `Artikel ${route.params.id}`,
  //     description: 'Deskripsi artikel',
  //   }),
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
