<template>
    <div class="p-4 margin-section-global">
        <div class="thumbnail position-relative">
            <div class="container">
                <div class="row thumbnail-row align-items-lg-end align-items-sm-end">
                    <div class="col-12 col-md-12 col-lg-6 h-100 align-items-center py-5 py-lg-0 d-flex">
                        <div>
                            <h1 class="mb-0 text-white bold-fs-48 mb-3">Kami akan membantu digitalisasi bisnis Kamu</h1>
                            <p class="regular-fs-20 text-white mb-5">Jangan sampai bisnis kamu tertinggal oleh perkembangan teknologi, bangun sekarang untuk memulai</p>
                            <button type="button" class="py-3 px-5 rounded-pill btn-app-primary bold-fs-14">
                                Mulai Pelajari 
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9198 14.205C23.1304 14.416 23.2488 14.7019 23.2488 15C23.2488 15.2981 23.1304 15.5841 22.9198 15.795L16.5448 22.17C16.4418 22.2805 16.3176 22.3692 16.1796 22.4307C16.0416 22.4922 15.8926 22.5252 15.7416 22.5279C15.5905 22.5306 15.4405 22.5028 15.3004 22.4462C15.1603 22.3896 15.033 22.3054 14.9262 22.1986C14.8194 22.0917 14.7352 21.9645 14.6786 21.8244C14.622 21.6843 14.5942 21.5343 14.5969 21.3832C14.5995 21.2322 14.6326 21.0832 14.6941 20.9452C14.7556 20.8072 14.8442 20.683 14.9548 20.58L20.5348 15L14.9548 9.42001C14.7561 9.20675 14.6479 8.92468 14.653 8.63323C14.6581 8.34178 14.7762 8.0637 14.9823 7.85758C15.1885 7.65146 15.4665 7.53339 15.758 7.52825C16.0494 7.52311 16.3315 7.63129 16.5448 7.83001L22.9198 14.205ZM9.04477 7.83001L15.4198 14.205C15.6304 14.416 15.7488 14.7019 15.7488 15C15.7488 15.2981 15.6304 15.5841 15.4198 15.795L9.04477 22.17C8.83151 22.3687 8.54944 22.4769 8.25799 22.4718C7.96653 22.4666 7.68846 22.3486 7.48234 22.1424C7.27622 21.9363 7.15815 21.6582 7.15301 21.3668C7.14786 21.0753 7.25605 20.7933 7.45477 20.58L13.0348 15L7.45477 9.42001C7.25605 9.20675 7.14786 8.92468 7.15301 8.63323C7.15815 8.34178 7.27622 8.0637 7.48234 7.85758C7.68846 7.65146 7.96653 7.53339 8.25799 7.52825C8.54944 7.52311 8.83151 7.63129 9.04477 7.83001Z" fill="white"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-md-12 col-lg-6">
                        <div class="mb-5 justify-content-center d-flex">
                            <div class="">
                                <p class="bg-white mb-0 text-dark px-3 regular-fs-11 fw-bold poppins-text rounded-pill position-relative py-3">Hallo, kenalin saya Home Bots... <svg width="32" class="position-absolute icon-left-tooltip" height="27" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M28.4444 24.2374C25.6 13.6611 8.2963 3.67233 0 0H32C32 12.4859 31.2889 34.8137 28.4444 24.2374Z" fill="white"/>
                                </svg></p>
                            </div>
                            <div class="m-3">
                                <p class="bg-white mb-0 text-dark px-3 regular-fs-9 poppins-text fw-bold rounded-pill py-3 position-relative">
                                    Ada yang bisa dibantu?
                                    <svg width="32" height="27" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg" class="position-absolute icon-right-tooltip">
                                        <path d="M3.55555 24.2374C6.4 13.6611 23.7037 3.67233 32 0H0C0 12.4859 0.711109 34.8137 3.55555 24.2374Z" fill="white"/>
                                    </svg>
                                </p>
                            </div>
                        </div>
                        <div class="text-center">
                            <img :src="HomeBotBanner" class="img-fluid" alt="homebot-thumbnail">
                        </div>
                    </div>
                </div>
            </div>
            <div class="backbottom-radius">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2933 17.7066C15.4808 17.8939 15.735 17.9991 16 17.9991C16.265 17.9991 16.5191 17.8939 16.7066 17.7066L26.7066 7.70662C26.8833 7.51706 26.9794 7.26633 26.9749 7.00726C26.9703 6.74819 26.8653 6.50101 26.6821 6.3178C26.4989 6.13458 26.2517 6.02963 25.9927 6.02506C25.7336 6.02049 25.4829 6.11665 25.2933 6.29329L16 15.5866L6.70662 6.29329C6.51706 6.11665 6.26633 6.02049 6.00726 6.02506C5.74819 6.02963 5.50101 6.13458 5.3178 6.3178C5.13458 6.50101 5.02963 6.74819 5.02506 7.00726C5.02049 7.26633 5.11665 7.51706 5.29329 7.70662L15.2933 17.7066Z" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2933 25.7067C15.4808 25.894 15.735 25.9992 16 25.9992C16.265 25.9992 16.5191 25.894 16.7066 25.7067L26.7066 15.7067C26.8049 15.6152 26.8837 15.5048 26.9383 15.3821C26.993 15.2594 27.0224 15.127 27.0247 14.9927C27.0271 14.8585 27.0024 14.7251 26.9521 14.6006C26.9018 14.4761 26.827 14.363 26.732 14.268C26.6371 14.173 26.5239 14.0982 26.3994 14.0479C26.2749 13.9976 26.1415 13.9729 26.0073 13.9753C25.873 13.9776 25.7406 14.007 25.6179 14.0617C25.4952 14.1163 25.3848 14.1951 25.2933 14.2934L16 23.5867L6.70662 14.2934C6.51706 14.1167 6.26633 14.0206 6.00726 14.0251C5.74819 14.0297 5.50101 14.1347 5.3178 14.3179C5.13458 14.5011 5.02963 14.7483 5.02506 15.0073C5.02049 15.2664 5.11665 15.5171 5.29329 15.7067L15.2933 25.7067Z" fill="white"/>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Thumbnail",
        data() {
            return {
                // Define Image
                HomeBotBanner: require('@/assets/website/image/medium/homebot-thumbnail.png'),
            }
        }
    }
</script>
