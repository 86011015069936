<template>
    <section class="service-section margin-section-global">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 text-center">
                            <h3 class="extra-bold-fs-32 text-app-primary shadow-text-primary">Layanan Kami</h3>
                            <p class="montserrat-text regular-fs-16 text-app-dark">Homebot siap membantu dengan skill yang dimiliki dan mencakup <br> beberapa kebutuhan kamu untuk mengembangkan bisnis</p>
                        </div>    
                        <div class="col-12">
                            <div class="d-lg-flex justify-content-center align-items-center">
                                <div class="m-lg-3 service-card position-relative">
                                    <div class="">
                                        <img class="mb-5" :src="mobileIcon" alt="" width="80" height="80">
                                        <h6 class="mb-2 bold-fs-16">Mobile Development</h6>
                                        <p class="mb-0 montserrat-text text-app-gray regular-fs-14">
                                            Website adalah hal terpenting saat ini untuk kebutuhan bisnis kamu, karena dengan memiliki website akan banyak customer baru berdatangan
                                        </p>
                                    </div>
                                    <div class="service-bg-icon">
                                        <div class="service-icon">
                                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3748 5.625H29.2498C29.5481 5.625 29.8343 5.74353 30.0453 5.9545C30.2562 6.16548 30.3748 6.45163 30.3748 6.75V23.625C30.3748 23.9234 30.2562 24.2095 30.0453 24.4205C29.8343 24.6315 29.5481 24.75 29.2498 24.75C28.9514 24.75 28.6653 24.6315 28.4543 24.4205C28.2433 24.2095 28.1248 23.9234 28.1248 23.625V9.465L7.54477 30.045C7.33151 30.2437 7.04944 30.3519 6.75799 30.3468C6.46653 30.3416 6.18846 30.2236 5.98234 30.0174C5.77622 29.8113 5.65815 29.5332 5.65301 29.2418C5.64786 28.9503 5.75605 28.6683 5.95477 28.455L26.5348 7.875H12.3748C12.0764 7.875 11.7903 7.75647 11.5793 7.5455C11.3683 7.33452 11.2498 7.04837 11.2498 6.75C11.2498 6.45163 11.3683 6.16548 11.5793 5.9545C11.7903 5.74353 12.0764 5.625 12.3748 5.625Z" fill="white"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-lg-flex justify-content-center flex-wrap col">

                                    <div class="m-lg-3 service-card position-relative">
                                        <div class="">
                                            <img class="mb-5" :src="webIcon" alt="" width="80" height="80">
                                            <h6 class="mb-2 bold-fs-16">Web Development</h6>
                                            <p class="mb-0 montserrat-text text-app-gray regular-fs-14">
                                                Website adalah hal terpenting saat ini untuk kebutuhan bisnis kamu, karena dengan memiliki website akan banyak customer baru berdatangan
                                            </p>
                                        </div>
                                        <div class="service-bg-icon">
                                            <div class="service-icon">
                                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3748 5.625H29.2498C29.5481 5.625 29.8343 5.74353 30.0453 5.9545C30.2562 6.16548 30.3748 6.45163 30.3748 6.75V23.625C30.3748 23.9234 30.2562 24.2095 30.0453 24.4205C29.8343 24.6315 29.5481 24.75 29.2498 24.75C28.9514 24.75 28.6653 24.6315 28.4543 24.4205C28.2433 24.2095 28.1248 23.9234 28.1248 23.625V9.465L7.54477 30.045C7.33151 30.2437 7.04944 30.3519 6.75799 30.3468C6.46653 30.3416 6.18846 30.2236 5.98234 30.0174C5.77622 29.8113 5.65815 29.5332 5.65301 29.2418C5.64786 28.9503 5.75605 28.6683 5.95477 28.455L26.5348 7.875H12.3748C12.0764 7.875 11.7903 7.75647 11.5793 7.5455C11.3683 7.33452 11.2498 7.04837 11.2498 6.75C11.2498 6.45163 11.3683 6.16548 11.5793 5.9545C11.7903 5.74353 12.0764 5.625 12.3748 5.625Z" fill="white"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="m-lg-3 service-card position-relative">
                                        <div class="">
                                            <img class="mb-5" :src="systemDigitalIcon" alt="" width="80" height="80">
                                            <h6 class="mb-2 bold-fs-16">Sistem Digital</h6>
                                            <p class="mb-0 montserrat-text text-app-gray regular-fs-14">
                                                Bangun sistem bisnis kamu dengan mendigitalisasi seluruh management office bersama kami
                                            </p>
                                        </div>
                                        <div class="service-bg-icon">
                                            <div class="service-icon">
                                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3748 5.625H29.2498C29.5481 5.625 29.8343 5.74353 30.0453 5.9545C30.2562 6.16548 30.3748 6.45163 30.3748 6.75V23.625C30.3748 23.9234 30.2562 24.2095 30.0453 24.4205C29.8343 24.6315 29.5481 24.75 29.2498 24.75C28.9514 24.75 28.6653 24.6315 28.4543 24.4205C28.2433 24.2095 28.1248 23.9234 28.1248 23.625V9.465L7.54477 30.045C7.33151 30.2437 7.04944 30.3519 6.75799 30.3468C6.46653 30.3416 6.18846 30.2236 5.98234 30.0174C5.77622 29.8113 5.65815 29.5332 5.65301 29.2418C5.64786 28.9503 5.75605 28.6683 5.95477 28.455L26.5348 7.875H12.3748C12.0764 7.875 11.7903 7.75647 11.5793 7.5455C11.3683 7.33452 11.2498 7.04837 11.2498 6.75C11.2498 6.45163 11.3683 6.16548 11.5793 5.9545C11.7903 5.74353 12.0764 5.625 12.3748 5.625Z" fill="white"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="m-lg-3 service-card position-relative">
                                    <div class="">
                                        <img class="mb-5" :src="collaborationIcon" alt="" width="80" height="80">
                                        <h6 class="mb-2 bold-fs-16">Berkolaborasi Dengan Kami</h6>
                                        <p class="mb-0 montserrat-text text-app-gray regular-fs-14">
                                            Kembangkan IDE mu bersama kami untuk memajukan dunia industri digital dengan Home Developer kita capai produk terbaik
                                        </p>
                                    </div>
                                    <div class="service-bg-icon">
                                        <div class="service-icon">
                                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3748 5.625H29.2498C29.5481 5.625 29.8343 5.74353 30.0453 5.9545C30.2562 6.16548 30.3748 6.45163 30.3748 6.75V23.625C30.3748 23.9234 30.2562 24.2095 30.0453 24.4205C29.8343 24.6315 29.5481 24.75 29.2498 24.75C28.9514 24.75 28.6653 24.6315 28.4543 24.4205C28.2433 24.2095 28.1248 23.9234 28.1248 23.625V9.465L7.54477 30.045C7.33151 30.2437 7.04944 30.3519 6.75799 30.3468C6.46653 30.3416 6.18846 30.2236 5.98234 30.0174C5.77622 29.8113 5.65815 29.5332 5.65301 29.2418C5.64786 28.9503 5.75605 28.6683 5.95477 28.455L26.5348 7.875H12.3748C12.0764 7.875 11.7903 7.75647 11.5793 7.5455C11.3683 7.33452 11.2498 7.04837 11.2498 6.75C11.2498 6.45163 11.3683 6.16548 11.5793 5.9545C11.7903 5.74353 12.0764 5.625 12.3748 5.625Z" fill="white"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "Service",
        data() {
            return {
                webIcon: require('@/assets/website/image/small/web-development.png'),
                mobileIcon: require('@/assets/website/image/small/mobile-development.png'),
                mobileIcon: require('@/assets/website/image/small/mobile-development.png'),
                collaborationIcon: require('@/assets/website/image/small/collaboration.png'),
                systemDigitalIcon: require('@/assets/website/image/small/system-digital.png'),
            }
        },

    }
</script>

<style scoped>

</style>