<template lang="">
   <!-- OPEN THUMBNAIL -->
   <Thumbnail />
   <!-- CLOSE THUMBNAIL -->

   <!-- OPEN TAGLINE CARD -->
   <Taglane />
   <!-- CLOSE TAGLINE CARD -->

   <!-- CLOSE TECH SECTION -->
    <Tech />
   <!-- CLOSE TECH SECTION -->

   <!-- OPEN PORTOFOLIO SECTION -->
    <Portfolio />
   <!-- CLOSE PORTOFOLIO SECTION -->

    <!-- OPEN SERVICE SECTION -->
    <Service />
   <!-- CLOSE SERVICE SECTION -->

    <!-- OPEN FAQ SECTION -->
    <Faq />
   <!-- CLOSE FAQ SECTION -->
</template>

<script>
import Thumbnail from '@/components/website/home/Thumbnail.vue'
import Taglane from '@/components/website/home/Taglane.vue'
import Tech from '@/components/website/home/Tech.vue'
import Portfolio from '@/components/website/home/Portfolio.vue'
import Service from '@/components/website/home/Service.vue'
import Faq from '@/components/website/home/Faq.vue'

export default {
    name: 'HomeView',
    components: {
        Thumbnail,
        Taglane,
        Tech,
        Portfolio,
        Service,
        Faq
    },
}
</script>

<style>

</style>