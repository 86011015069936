<template>
    <nav :class="{ 'rounded-pill mt-5': isDesktop, 'rounded': isMobileOrTablet, 'navbar-hidden': isNavbarHidden }" class="navbar container navbar-expand-lg fixed-top bg-light p-lg-2 p-3" >
        <a class="navbar-brand" href="#"> <img :src="logoNavbar" width="127" alt="Logo"></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
        <ul class="navbar-nav mx-auto">
            <li class="nav-item text-center mx-lg-3 dropdown">
                <a class="nav-link regular-fs-12 dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                    Kami Bisa
                </a>
                <ul class="dropdown-menu" aria-label="dropdownMenuLink">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li><hr class="dropdown-divider"></li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
                </ul>
            </li>
            <li class="nav-item text-center mx-lg-3">
                <a class="nav-link regular-fs-12" href="#">Product kami</a>
            </li>
            <li class="nav-item text-center mx-lg-3">
                <a class="nav-link regular-fs-12" href="#">Karya kami</a>
            </li>
            <li class="nav-item text-center mx-lg-3">
                <a class="nav-link regular-fs-12" href="#">Cerita Kami</a>
            </li>
            <li class="nav-item text-center mx-lg-3">
                <a class="nav-link regular-fs-12" href="#">Hubungi</a>
            </li>
        </ul>
        <ul class="navbar-nav">
            <li class="nav-item text-center">
                <a class="nav-link"><button class="py-3 px-4 rounded-pill btn-app-primary bold-fs-14">Yuk Hubungi</button></a>
            </li>
        </ul>
        </div>
    </nav>
</template>

<script>
    export default {
        name: "Navbar",
        data() {
            return {
                isMobileOrTablet: false,
                isDesktop: false,
                isNavbarHidden: false,
                lastScrollTop: 0,
                logoNavbar: require('@/assets/website/logo/logo-web-block.png'),
            };
        },
        mounted() {
            this.checkScreenSize();
            window.addEventListener('resize', this.checkScreenSize);
            window.addEventListener('scroll', this.handleScroll);
        },
        methods: {
            checkScreenSize() {
            this.isMobileOrTablet = window.innerWidth < 992;
            this.isDesktop = window.innerWidth >= 992;
            },
            handleScroll() {
                const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

                if (currentScrollTop > this.lastScrollTop) {
                    // Scroll down
                    this.isNavbarHidden = true;
                } else {
                    // Scroll up
                    this.isNavbarHidden = false;
                }

                this.lastScrollTop = currentScrollTop;
            }
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.checkScreenSize);
            window.removeEventListener('scroll', this.handleScroll);
        }
    }
</script>

<style>
</style>