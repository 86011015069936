<template>
<section class="tech-section margin-section-global">
        <div class="container">
            <div class="row tech-update-section align-items-center p-lg-5">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-6 mb-3 py-4">
                            <h2 class="text-app-primary extra-bold-fs-38 mb-5 shadow-text-primary">Dengan teknologi canggih terupdate</h2>
                            <p class="mb-3 text-app-dark regular-fs-16 montserrat-text mb-4 letter-spacing-default">Untuk mengembangkan bisnis, kamu perlu yang cepat dan efisien agar bisnis berjalan dengan lancar dan berkembang secara pesat</p>
                            <div class="business-tag-group p-3">
                                <p class="mb-0 text-white montserrat-text bold-fs-16 mb-3">Pembisnis yang Terbantu</p>
                                <div class="d-flex justify-content-between text-white">
                                    <div class="position-relative personal-tag-group d-flex">
                                        <div class="tag-personal left-0">
                                            A
                                        </div>
                                        <div class="tag-personal left-25">
                                            B
                                        </div>
                                        <div class="tag-personal left-50">
                                            C
                                        </div>
                                        <div class="tag-personal left-75">
                                            D
                                        </div>
                                    </div>
                                    <div class="start-work">
                                        <a href="#" class="cursor-pointer text-decoration-none text-white">
                                            Mulai <font-awesome-icon :icon="['fas', 'arrow-right']" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                            <div class="d-lg-flex mobile-tech-position justify-content-center align-items-center">
                                <div class="m-3 text-center icon-shadow-tech">
                                    <img :src="reactIcon" alt="react-icon">
                                </div>
                                <div class="m-3 text-center icon-shadow-tech">
                                    <img :src="javaIcon" alt="java-icon">
                                </div>
                                <div class="m-3 text-center icon-shadow-tech">
                                    <img :src="flutterIcon" alt="flutter-icon">
                                </div>
                            </div>
                            <div class="d-lg-flex mobile-tech-position justify-content-center align-items-center">
                                <div class="m-3 text-center icon-shadow-tech">
                                    <img :src="androidIcon" alt="android-icon">
                                </div>
                                <div class="m-3 text-center icon-shadow-tech">
                                    <img :src="goIcon" alt="go-icon">
                                </div>
                                <div class="m-3 text-center icon-shadow-tech">
                                    <img :src="vueIcon" alt="vue-icon">
                                </div>
                                <div class="m-3 text-center icon-shadow-tech">
                                    <img :src="pythonIcon" alt="python-icon">
                                </div>
                            </div>
                            <div class="d-lg-flex mobile-tech-position justify-content-center align-items-center">
                                <div class="m-3 text-center icon-shadow-tech">
                                    <img :src="laravelIcon" alt="laravel-icon">
                                </div>
                                <div class="m-3 text-center icon-shadow-tech">
                                    <img :src="nodeIcon" alt="node-icon">
                                </div>
                                <div class="m-3 text-center icon-shadow-tech">
                                    <img :src="figmaIcon" alt="figma-icon">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </section>
</template>

<script>
    export default {
        name: 'Tech',
        data() {
            return {
                reactIcon: require("@/assets/website/image/small/react-skill.png"),
                javaIcon: require("@/assets/website/image/small/java-skill.png"),
                flutterIcon: require("@/assets/website/image/small/flutter-skill.png"),
                androidIcon: require("@/assets/website/image/small/android-skill.png"),
                goIcon: require("@/assets/website/image/small/golang-skill.png"),
                vueIcon: require("@/assets/website/image/small/vue-skill.png"),
                pythonIcon: require("@/assets/website/image/small/python-skill.png"),
                laravelIcon: require("@/assets/website/image/small/laravel-skill.png"),
                nodeIcon: require("@/assets/website/image/small/node-skill.png"),
                figmaIcon: require("@/assets/website/image/small/figma-skill.png"),
            }
        },
    }
</script>